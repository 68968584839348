import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/hui-yuan-ding-dan-ji-lu/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/hui-yuan-ding-dan-ji-lu/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/hui-yuan-ding-dan-ji-lu/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/hui-yuan-ding-dan-ji-lu/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/hui-yuan-ding-dan-ji-lu/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/hui-yuan-ding-dan-ji-lu/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/hui-yuan-ding-dan-ji-lu/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/hui-yuan-ding-dan-ji-lu/query',
        method: 'post',
        data
    })
}

export function huiyuanlogin(data) {
    return request({
        url: `/hui-yuan-xx/login/${data}`,
        method: 'post',
    })
}

export function huiyuanzhifu(data) {
    return request({
        url: '/hui-yuan-xx/hui-yuan-zhi-fu',
        method: 'post',
        data
    })
}

export function createDingDan(data) {
    return request({
        url: '/hui-yuan-ding-dan-ji-lu/addDingDan',
        method: 'post',
        data
    })
}

export function tuiKuan(data) {
    return request({
        url: '/hui-yuan-xx/tuiKuan',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'hui-yuan-ding-dan-ji-lu-list',
    component: () => import('@/view/hygl/hyddjl/HuiYuanDingDanJiLuList'),
    name: 'HuiYuanDingDanJiLuList',
    meta: {title: '会员订单记录', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'hui-yuan-ding-dan-ji-lu-query',
    component: () => import('@/view/hygl/hyddjl/HuiYuanDingDanJiLuQuery'),
    name: 'HuiYuanDingDanJiLuQuery',
    meta: {title: '会员订单记录查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'hui-yuan-ding-dan-ji-lu-remind-list',
    component: () => import('@/view/hygl/hyddjl/HuiYuanDingDanJiLuRemindList'),
    name: 'HuiYuanDingDanJiLuRemindList',
    meta: {title: '会员订单记录提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"会员订单记录基础列表","methodUrl":"/hui-yuan-ding-dan-ji-lu/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"会员订单记录添加保存","methodUrl":"/hui-yuan-ding-dan-ji-lu/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"会员订单记录获取编辑数据","methodUrl":"/hui-yuan-ding-dan-ji-lu/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"会员订单记录获取详情数据","methodUrl":"/hui-yuan-ding-dan-ji-lu/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"会员订单记录编辑保存","methodUrl":"/hui-yuan-ding-dan-ji-lu/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"会员订单记录删除","methodUrl":"/hui-yuan-ding-dan-ji-lu/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"会员订单记录批量删除","methodUrl":"/hui-yuan-ding-dan-ji-lu/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"会员订单记录独立查询","methodUrl":"/hui-yuan-ding-dan-ji-lu/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
