<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="订单号" >
                <el-input v-model="search.dingDanHao" size="small" />
            </x-search-item>
            <x-search-item label="卡号" >
                <el-input v-model="search.kaHao" size="small" />
            </x-search-item>
            <x-search-item label="姓名" >
                <el-input v-model="search.xingMing" size="small" />
            </x-search-item>
            <x-search-item label="手机号" >
                <el-input v-model="search.shouJiHao" size="small" />
            </x-search-item><br>
            <x-search-item label="消费时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.xiaoFeiSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.xiaoFeiSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
<!--            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>-->
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
        </div>

        <!--        支付模拟-->
<!--        <div style="border: 3px solid skyblue; text-align: center;width: 500px;margin: auto;padding-top: 10px" >-->
<!--            <div>-->
<!--                    <el-button    type="warning" size="mini" round  @click="denglumoni">会员登录</el-button>-->

<!--                    <el-button   type="primary" size="mini" round  @click="zhifumonishow">支付</el-button>-->
<!--            </div><br>-->
<!--            &lt;!&ndash;        会员信息&ndash;&gt;-->
<!--            <div>-->
<!--                <label style="font-weight: bold">姓名：{{xx.xingMing}}</label><br>-->
<!--                <label>手机号：{{xx.shouJiHao}}</label><br>-->
<!--                <label>卡号：{{xx.kaHao}}</label><br>-->
<!--                <label>余额：{{xx.shengYuJinE}}</label><br>-->
<!--                <label>积分：{{xx.shengYuJiFen}}</label><br>-->
<!--                <label v-show="xx.huiYuanLeiXing===hylx.CGHY.key">享受折扣：{{djxx.xiangShouZheKou}}</label>-->
<!--                <label v-show="xx.huiYuanLeiXing===hylx.PLUSHY.key">plus折扣：{{plusZK}}</label><br>-->
<!--            </div>-->
<!--        </div>-->


<!--        登录框-->
        <el-dialog title="会员登录" :visible.sync="loginFormVisible" >
            <el-form :model="loginform"  >
                <el-form-item label="手机号" :label-width="formLabelWidth"
                >
                    <el-input v-model="loginform.phone" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="loginFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="hylogin()">确 定</el-button>
            </div>
        </el-dialog>
        <!--        支付密码框-->
        <el-dialog title="输入支付密码" :visible.sync="pwdFormVisible" >
            <el-form :model="pwdform"  >
                <el-form-item label="消费金额" :label-width="formLabelWidth">
                    <el-input v-model="pwdform.xiaofeijine" autocomplete="off">                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth">
                    <el-input v-model="pwdform.pwd" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="pwdFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="hypwd">确 定</el-button>
            </div>
        </el-dialog>

        <!--        退款-->
        <el-dialog title="商品退款" :visible.sync="tkFormVisible" >
            <el-form :model="tkform"  :rules="rules">
                <el-form-item label="消费金额" :label-width="formLabelWidth">
                    <el-input v-model="tkform.xiaoFeiJinE" autocomplete="off" disabled>
                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>
                </el-form-item>
                <el-form-item label="实付金额" :label-width="formLabelWidth" >
                    <el-input v-model="tkform.shiFuJinE" autocomplete="off" disabled>                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>                </el-form-item>
                <el-form-item label="请输入退款金额" :label-width="formLabelWidth" prop="tuiKuanJinE">
                    <el-input v-model="tkform.tuiKuanJinE" autocomplete="off" >                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>                </el-form-item>
                <el-form-item label="抵扣积分" :label-width="formLabelWidth" >
                    <el-input v-model="tkform.diKouJiFen" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="退回积分" :label-width="formLabelWidth" prop="tuiHuiJiFen">
                    <el-input v-model="tkform.tuiHuiJiFen" autocomplete="off" ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="tkFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="tuiKuanConfirm">确 定</el-button>
            </div>
        </el-dialog>



        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="dingDanHao" label="订单号" />
            <el-table-column width="180" prop="kaHao" label="卡号" />
            <el-table-column width="180" prop="xingMing" label="姓名" />
            <el-table-column width="180" prop="shouJiHao" label="手机号" />
            <el-table-column width="180" prop="xiaoFeiSJ" label="消费时间" />
            <el-table-column width="180" prop="xiaoFeiJinE" label="消费金额" />
            <el-table-column width="180" prop="shiFuJinE" label="实付金额" />
            <el-table-column width="180" prop="diKouJiFen" label="抵扣积分" />
            <el-table-column width="180" prop="dingDanZhuangTai" label="订单状态" />
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
<!--                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>-->
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>

                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/HuiYuanDingDanJiLu";
    import * as menDianXXservice from "@/service/mdgl/MenDianXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/hyddjl/HuiYuanDingDanJiLuEdit";
    import Detail from "@/view/hygl/hyddjl/HuiYuanDingDanJiLuDetail";
    import {ruleBuilder,isDigits} from "@/util/validate";
    import {DICT_TYPE_HUI_YUAN_LX, DICT_TYPE_DDJLZT} from "@/util/constant";

    export default {
        name: "HuiYuanDingDanJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            let {required,positiveEightTwo,digits}=ruleBuilder;
            return {
                hylx:DICT_TYPE_HUI_YUAN_LX,
                plusZK:'',
                xiaofeijine:null,
                shiJiZhiFu:null,
                dingDanHao: "",
                caoZuoRen:'',
                xx: {
                    caoZuoRen:"",
                    chongZhiJinE: 50,
                    classification: "",
                    createTime: "",
                    creatorId: "",
                    huiYuanDJ: "1",
                    id: "",
                    kaHao: "",
                    kaZhuangTai: "",
                    kaiKaSJ: "",
                    leiJiJiFen: 0,
                    miMa: "",
                    huiYuanLeiXing: "", // 会员类型
                    huiYuanBiaoQian: "", // 会员标签
                    plusZheKou: "", // plus折扣
                    ownerAreaCode: "",
                    ownerDeptCode: "",
                    ownerDeptName: "",
                    ownerOrganCode: "",
                    ownerOrganName: "",
                    shengYuJiFen: null,
                    shengYuJinE: null,
                    shiFuShanChu: "",
                    shiFuZhuXiao: "",
                    shouJiHao: "",
                    suoShuMenDian: "",
                    updateTime: "",
                    updaterId: "",
                    xingMing: "",
                    zengSongJinE: 2
                },
                djxx:{
                    classification: "",
                    createTime: "",
                    creatorId: "",
                    huiYuanDJ: "",
                    huiYuanMC: "",
                    id: "",
                    ownerAreaCode: "",
                    ownerDeptCode: "",
                    ownerDeptName: "",
                    ownerOrganCode: "",
                    ownerOrganName: "",
                    updateTime: "",
                    updaterId: "",
                    xiangShouZheKou: null,
                    zuiDiJiFen: null,
                },
                ddxx:{
                    diKouJiFen:"",
                    dingDanHao: "",
                    dingDanZhuangTai: "",
                    kaHao: "",
                    shiFuJinE:null ,
                    shouJiHao: "",
                    xiaoFeiJinE: null,
                    xiaoFeiSJ: "",
                    xingMing: "",
                },
                rules:{
                    tuiKuanJinE:[required(),positiveEightTwo()],
                    tuiHuiJiFen:[required(),digits()],
                },
                search: {
                    dingDanHao: '',
                    kaHao: '',
                    xingMing: '',
                    shouJiHao: '',
                    xiaoFeiSJBegin: '',
                    xiaoFeiSJEnd: '',
                },
                //会员登录表单
                formLabelWidth:'150px',
                loginFormVisible: false,
                loginform: {
                    phone: '',
                },
                //密码表单
                pwdFormVisible: false,
                pwdform: {
                    xiaofeijine:0,
                    pwd: '',
                },
                //密码表单
                tkFormVisible: false,
                tkform: {
                    id:'',
                    xiaoFeiJinE:0,
                    shiFuJinE:0,
                    tuiKuanJinE:0,
                    diKouJiFen:0,
                    tuiHuiJiFen:0,
                },
                isShanghu:'',
                userName:'',
                menDianMC:'',
            }
        },
        mounted() {
            menDianXXservice.getCurrentMenDianXXFromRedis().then(res=>{
                console.log(this.$store.getters.user);
                if(!res.data){
                    this.userName=this.$store.getters.user.name;
                    this.menDianMC="商户"
                    this.isShanghu=true;
                }else {
                    this.userName=this.$store.getters.user.name;
                    this.menDianMC=res.data.menDianMC;
                    console.log(this.userName);
                    console.log(this.menDianMC)
                }
            })
        },
        methods:{
            // getDingDanStr(){
            //     // 获取当前时间
            //     const now = new Date();
            //     // 获取年、月、日对应的数字字符串
            //     const yearStr = String(now.getFullYear());
            //     const monthStr = String(now.getMonth() + 1).padStart(2, '0'); // 加1是因为月份从0开始计数，padStart是为了补齐两位数
            //     const dayStr = String(now.getDate()).padStart(2, '0');
            //     const timestamp = Date.now().toString();
            //     const lastSixDigits = timestamp.slice(-6);
            //     console.log(lastSixDigits);
            //
            //     // 组合生成数字字符串
            //     const dingDanString = mendian+ yearStr + monthStr + dayStr + lastSixDigits;
            //
            //     this.dingDanHao=dateString;
            //     return dingDanString;
            // },
            denglumoni(){
                this.loginFormVisible = true;
            },
            hylogin(){
                console.log("122312313")
                var phone=this.loginform.phone;
                if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(phone)){
                    this.loginFormVisible = false;
                    service.huiyuanlogin(phone).then(res=>{
                        console.log(res);
                        if (res.code===200){
                            this.xx=res.data.huiYuanXX
                            if (res.data.huiYuanXX.huiYuanLeiXing===this.hylx.CGHY.key){
                                this.djxx=res.data.huiYuanDJXX
                            }
                            if (res.data.huiYuanXX.huiYuanLeiXing===this.hylx.PLUSHY.key){
                                this.plusZK=res.data.huiYuanXX.plusZheKou
                            }
                            console.log(this.xx)
                            console.log(this.djxx)
                            this.refresh();
                            this.$message.success("登录成功");
                            this.loginform.phone=""
                        }else {
                            this.$message.warning("请核对手机号")
                            this.loginFormVisible = true;
                        }
                    })
                }else {
                    this.$message.warning("手机号格式错误")
                }

            },
            zhifumonishow(){
                this.pwdFormVisible = true;
            },
            hypwd(){
                this.pwdFormVisible=false;
                let data={
                    id:this.xx.id,
                    xiaoFeiJinE:this.pwdform.xiaofeijine,
                    caoZuoRen:this.userName,
                    xiangShouZheKou:this.djxx.xiangShouZheKou,
                    menDian:this.menDianMC,
                };
                if (this.xx.id!=null){
                    if (this.xx.miMa===this.pwdform.pwd){
                        console.log(this.xx.shengYuJinE)
                        console.log(data.xiaoFeiJinE)

                        if (this.xx.shengYuJinE*1<data.xiaoFeiJinE*this.djxx.xiangShouZheKou){
                            console.log("000000")
                            this.$message.warning("余额不足")
                            return
                        }
                        service.huiyuanzhifu(data).then(res=>{
                            //生成订单
                            if (res.code===200){
                                this.$message.success("支付成功")
                                this.pwdform.pwd='';
                                this.pwdform.xiaofeijine=0;
                                this.xx={};
                                this.djxx={};
                                this.refresh()
                                // let data2={
                                //     dingDanHao:this.getDingDanStr(),
                                //     id:data.id,
                                //     kaHao:this.xx.kaHao,
                                //     xingMing:this.xx.xingMing,
                                //     shouJiHao:this.xx.shouJiHao,
                                //     xiaoFeiSJ:new Date(),
                                //     xiaoFeiJinE:data.xiaoFeiJinE,
                                // }

                            }

                        })
                    }else {
                        this.$message.warning("密码错误")
                    }
                }
                this.refresh()
            },
            tuiKuanShow(id){
                this.tkFormVisible=true;
                service.getDetail(id).then(res=>{
                    console.log(res)
                    this.ddxx=res.data;
                    this.tkform.id=id;
                    this.tkform.shiFuJinE=this.ddxx.shiFuJinE;
                    this.tkform.xiaoFeiJinE=this.ddxx.xiaoFeiJinE;
                    this.tkform.diKouJiFen=this.ddxx.diKouJiFen;
                    console.log(this.tkform)
                })
            },
            tuiKuanConfirm(id){

                if (this.tkform.shiFuJinE<this.tkform.tuiKuanJinE){
                    this.$message.warning("退款金额不能大于实付金额");
                    return null;
                }
                if (this.tkform.tuiKuanJinE<=0){
                    this.$message.warning("退款金额不能为0");
                    return null;
                }
                if (this.tkform.tuiHuiJiFen>this.tkform.diKouJiFen || !isDigits(this.tkform.tuiHuiJiFen)){
                    this.$message.warning("退回积分不能超过抵扣积分");
                    return null;
                }
                this.tkFormVisible=false;
                console.log("退款金额："+this.tkform.tuiKuanJinE);
                let data={
                    ddId:this.tkform.id,
                    tuiKuanJinE:this.tkform.tuiKuanJinE,
                    tuiHuiJiFen:this.tkform.tuiHuiJiFen,
                    xingMing:this.ddxx.xingMing,
                    kaHao:this.ddxx.kaHao,
                    tuiKuanSJ:new Date(),
                    caoZuoRen:this.$store.getters.user.name,
                    bianDongMenDian:this.menDianMC
            };
                service.tuiKuan(data).then(res=>{
                    if (res.code===200){
                        this.tkform.tuiKuanJinE=0;
                        console.log(this.tkform.tuiKuanJinE)
                        this.$message.success("退款成功");
                        this.refresh()
                    }
                })
                this.refresh()
            },
            xianshi(num){
                console.log(this.tkform.tuiKuanJinE)
            }
        }
    }
</script>

<style scoped>
</style>
